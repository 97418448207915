<template>
  <v-card>
    <v-container>
      <h3> หน้าแสดงข้อมูลจัดการผู้สมัครสมาชิก </h3>
      <v-row>
        <v-col cols="4" class="mr-4">
          <v-text-field
            v-model="search"
            dense
            hide-details
            outlined
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :search="search"
        :items-per-page="10"
        class="elevation-1 mt-4"
        :loading="loading"
        loading-text="กำลังโหลดข้อมูล...กรุณารอสักครู่"
      >
        <template v-slot:[`item.idCardImg`]="{ item }">
          <img @click="popup(item)" :src="item.idCardImg" width="30px" />
        </template>

        <template v-slot:[`item.activeFlag`]="{ item }">
          <v-row justify="center">
            <v-btn v-if="item.activeFlag === true" disabled>อนุมัติแล้ว</v-btn>
            <v-btn @click="approve(item)" v-else>รออนุมัติ</v-btn>
            <v-icon @click="deleteUser(item)">mdi-delete</v-icon>
          </v-row>
        </template>
        <!-- <template v-slot:[`item.action`]="{ item }">
          <v-row justify="center">
            <v-icon @click="deleteData(item)">mdi-delete</v-icon>
            <v-icon @click="Editdata(item)">mdi-magnify</v-icon>
          </v-row>
        </template> -->
      </v-data-table>
      <v-dialog v-model="dialog" max-width="600">
        <v-card max-height="750">
          <v-card-text>
            <v-col cols="12">
              <v-img :src="idCardImg" />
              <v-spacer></v-spacer>
              <v-col cols="12" align="right">
                <v-btn
                  class="btn btn-secondary f-right"
                  color="#744D9c"
                  style="color: white"
                  @click="dialog = false"
                >
                  ปิด
                </v-btn>
              </v-col>
            </v-col>
          </v-card-text>
        </v-card>
      </v-dialog>
      <template v-slot:[`item.count`]="{ item }">
        {{ item.count }}
      </template>
    </v-container>
  </v-card>
</template>
<script>
import axios from "axios";
import { Decode } from "@/services";
export default {
  data() {
    return {
      search: "",
      dialog: false,
      loading: true,
      headers: [
        { text: "ลำดับ", value: "count", align: "center" },
        // { text: "รูป", value: "idCardImg", align: "center" },
        { text: "ชื่อ-นามสกุล", value: "name", align: "center" },
        { text: "หมายเลขบัตรประชาชน", value: "idCard", align: "center" },
        { text: "หมายเลขโทรศัพท์", value: "tel", align: "center" },
        { text: "อีเมลล์", value: "email", align: "center" },
        { text: "การจัดการ", value: "activeFlag", align: "center" },
        // { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      list: [],
      count: 0,
      idCardImg: "",
      checkuser: [],
    };
  },
  created() {
    this.checkuser = JSON.parse(Decode.decode(localStorage.getItem("user")));
    this.getAlluser();
  },

  methods: {
    deleteUser(item) {
      const id = item.id;
      console.log("id", id);
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const auth = {
            headers: { Authorization: `Bearer` + " " + this.checkuser.token },
          };

          await axios.delete(`${process.env.VUE_APP_API}/users/` + id, auth);
          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          await this.getAlluser();
        }
      });
    },

    async approve(item) {
      const auth = {
        headers: { Authorization: `Bearer` + " " + this.checkuser.token },
      };

      // console.log(token);
      const response = await axios.put(
        `${process.env.VUE_APP_API}/users/approve/` + item.id,
        auth
      );
      console.log("ดาต้าที่ออกมา", response.data);
      console.log("id", item.id);
      // this.list = response.data.data;
      await this.getAlluser();
    },
    popup(url) {
      this.dialog = !this.dialog;
      this.idCardImg = url.idCardImg;
      console.log("image", this.idCardImg);
    },
    async getAlluser() {
      const auth = {
        headers: { Authorization: `Bearer` + " " + this.checkuser.token },
      };

      // console.log(token);
      const response = await axios.get(
        `${process.env.VUE_APP_API}/users`,
        auth
      );
      console.log("ดาต้าที่ออกมา", response.data.data);
      this.list = response.data.data;
      for (let i in this.list) {
        this.list[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.f-right {
  float: right;
}
</style>
